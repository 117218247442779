.ourCompany {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
  padding-top: 180px;
  padding-bottom: 250px;
  background-color: #161616;
}

@media (min-width: 651px) {
  .ourCompany {
    background-image: url("../assets/images/our_company_mobile_bg.png");
  }
}

@media (min-width: 997px) {
  .ourCompany {
    background-image: url("../assets/images/our_company_bg.png");
  }
}

@media (max-width: 1440px) {
  .ourCompany {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 650px) {
  .ourCompany {
    padding: 0px;
  }
}

.ourCompany__container {
  font-family: "Playfair Display";
  margin: 0 auto 0 100px;
  padding: 50px 100px;
  background-color: #fff;
  border-radius: 80px;
}

@media (min-width: 1601px) {
  .ourCompany__container {
    max-width: 1170px;
  }
}

@media (max-width: 1600px) {
  .ourCompany__container {
    max-width: 1090px;
  }
}

@media (max-width: 1440px) {
  .ourCompany__container {
    padding: 50px 50px;
    max-width: 900px;
  }
}

@media (max-width: 1280px) {
  .ourCompany__container {
    max-width: 810px;
  }
}

@media (max-width: 1100px) {
  .ourCompany__container {
    max-width: 710px;
  }
}

@media (max-width: 1024px) {
  .ourCompany__container {
    margin: 0 50px;
    max-width: 100%;
    border-radius: 30px;
    padding: 25px 25px;
  }
}

@media (max-width: 650px) {
  .ourCompany__container {
    margin: 0;
    border-radius: 0px;
    padding: 50px 25px;
  }
}

.ourCompany__title {
  display: inline-block;
  border-bottom: 10px solid #bf986c;
  padding-bottom: 40px;
  margin-bottom: 40px;
  font-size: 52px;
  line-height: 120%;
}

@media (max-width: 650px) {
  .ourCompany__title {
    font-size: 40px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.ourCompany__content {
  font-size: 24px;
  line-height: 160%;
  text-align: justify;
}

@media (max-width: 1440px) {
  .ourCompany__content {
    font-size: 20px;
  }
}

@media (max-width: 900px) {
  .ourCompany__content {
    font-size: 18px;
  }
}

@media (max-width: 650px) {
  .ourCompany__content {
    font-size: 16px;
  }
}

.ourCompany__content:last-child {
  padding-top: 50px;
}

@media (max-width: 900px) {
  .ourCompany__content:last-child {
    padding-top: 25px;
  }
}
