.ourMission {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
  padding-top: 180px;
  padding-bottom: 250px;
  background-color: #161616;
}

@media (min-width: 651px) {
  .ourMission {
    background-image: url("../assets/images/our_mission_mobile_bg.png");
  }
}

@media (min-width: 997px) {
  .ourMission {
    background-image: url("../assets/images/our_mission_bg.png");
  }
}

@media (max-width: 1440px) {
  .ourMission {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 650px) {
  .ourMission {
    padding: 0px;
  }
}

.ourMission__container {
  font-family: "Playfair Display";
  margin: 0 auto 0 100px;
  padding: 50px 100px;
  background-color: #fff;
  border-radius: 80px;
}

@media (min-width: 1601px) {
  .ourMission__container {
    max-width: 1170px;
  }
}

@media (max-width: 1600px) {
  .ourMission__container {
    max-width: 1090px;
  }
}

@media (max-width: 1440px) {
  .ourMission__container {
    padding: 50px 50px;
    max-width: 900px;
  }
}

@media (max-width: 1280px) {
  .ourMission__container {
    max-width: 810px;
  }
}

@media (max-width: 1100px) {
  .ourMission__container {
    max-width: 710px;
  }
}

@media (max-width: 1024px) {
  .ourMission__container {
    margin: 0 50px;
    max-width: 100%;
    border-radius: 30px;
    padding: 25px 25px;
  }
}

@media (max-width: 650px) {
  .ourMission__container {
    margin: 0;
    border-radius: 0px;
    padding: 50px 25px;
  }
}

.ourMission__title {
  display: inline-block;
  border-bottom: 10px solid #bf986c;
  padding-bottom: 40px;
  margin-bottom: 40px;
  font-size: 52px;
  line-height: 120%;
}

@media (max-width: 650px) {
  .ourMission__title {
    font-size: 40px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.ourMission__title:not(:first-child) {
  margin-top: 150px;
}

@media (max-width: 1440px) {
  .ourMission__title:not(:first-child) {
    margin-top: 100px;
  }
}

@media (max-width: 1020px) {
  .ourMission__title:not(:first-child) {
    margin-top: 50px;
  }
}

.ourMission__content {
  font-size: 24px;
  line-height: 160%;
  text-align: justify;
}

@media (max-width: 1440px) {
  .ourMission__content {
    font-size: 20px;
  }
}

@media (max-width: 900px) {
  .ourMission__content {
    font-size: 18px;
  }
}

@media (max-width: 650px) {
  .ourMission__content {
    font-size: 16px;
  }
}

.ourMission__content:not(:last-child) {
  margin-bottom: 50px;
}

@media (max-width: 1020px) {
  .ourMission__content:not(:last-child) {
    margin-bottom: 25px;
  }
}

.ourMission__button {
  margin-left: 100px;
}

@media (max-width: 650px) {
  .ourMission__button {
    margin-left: 0px;
    padding-bottom: 130px;
  }
}

.ourMission__button a {
  padding: 16px 22px;
  border: 2px solid #bf986c;
  border-radius: 8px;
  background-color: #000;
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 130%;
  color: #fff;
  border-radius: 15.2px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .ourMission__button a {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .ourMission__button a {
    font-size: 18px;
  }
}

@media (max-width: 414px) {
  .ourMission__button a {
    font-size: 16px;
  }
}

.ourMission__button a:hover {
  background-color: #bf986cac;
  -webkit-box-shadow: 0px 4px 144px rgba(255, 237, 78, 0.85);
          box-shadow: 0px 4px 144px rgba(255, 237, 78, 0.85);
}
