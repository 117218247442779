.header-toggle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000bf;
  z-index: 2;
}

.header-toggle-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
}

.logo-text {
  color: #bf986c;
}

.hamb-line-consultation {
  background: #bf986c;
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
}

.hamb-line-consultation::before,
.hamb-line-consultation::after {
  background: #bf986c;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 100%;
}

.hamb-line-consultation::before {
  top: 5px;
}

.hamb-line-consultation::after {
  top: -5px;
}

.side-menu:checked ~ .hamb .hamb-line-consultation {
  background: transparent;
}

.side-menu:checked ~ .hamb .hamb-line-consultation::before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 0;
  background: #bf986c;
}

.side-menu:checked ~ .hamb .hamb-line-consultation::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 0;
  background: #bf986c;
}

.hamb-line {
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
}

.hamb-line::before,
.hamb-line::after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 100%;
}

.hamb-line::before {
  top: 5px;
}

.hamb-line::after {
  top: -5px;
}

.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}

.side-menu:checked ~ .hamb .hamb-line::before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 0;
  background: #bf986c;
}

.side-menu:checked ~ .hamb .hamb-line::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 0;
  background: #bf986c;
}

@media (min-width: 997px) {
  .header-toggle-consultation {
    display: none;
  }
}

@media (min-width: 997px) {
  .header-toggle {
    display: none;
  }
}
