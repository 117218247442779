.ourServices {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #161616;
}

.ourServices__container {
  font-family: "Playfair Display";
  max-width: 1700px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1600px) {
  .ourServices__container {
    max-width: 1400px;
  }
}

@media (max-width: 1439px) {
  .ourServices__container {
    max-width: 1240px;
  }
}

@media (max-width: 1280px) {
  .ourServices__container {
    max-width: 1080px;
  }
}

@media (max-width: 1100px) {
  .ourServices__container {
    max-width: 950px;
  }
}

@media (max-width: 1024px) {
  .ourServices__container {
    max-width: 924px;
  }
}

@media (max-width: 931px) {
  .ourServices__container {
    max-width: 814px;
  }
}

@media (max-width: 839px) {
  .ourServices__container {
    max-width: 739px;
  }
}

@media (max-width: 768px) {
  .ourServices__container {
    max-width: 668px;
  }
}

@media (max-width: 700px) {
  .ourServices__container {
    max-width: 600px;
  }
}

@media (max-width: 650px) {
  .ourServices__container {
    max-width: 550px;
  }
}

.ourServices__title {
  margin-bottom: 50px;
}

.ourServices__title_text {
  font-size: 52px;
  line-height: 120%;
  color: #fff;
}

@media (min-width: 997px) {
  .ourServices__title_text {
    margin-left: 60px;
  }
}

@media (min-width: 650px) {
  .ourServices__title_text {
    margin-left: 30px;
  }
}

@media (max-width: 650px) {
  .ourServices__title_text {
    font-size: 42px;
    margin-bottom: 0px;
    margin-left: 6%;
  }
}

@media (max-width: 510px) {
  .ourServices__title_text {
    font-size: 42px;
    margin-bottom: 0px;
    margin-left: 2%;
  }
}

@media (max-width: 480px) {
  .ourServices__title_text {
    font-size: 42px;
    margin-bottom: 0px;
    margin-left: 10%;
  }
}

@media (max-width: 350px) {
  .ourServices__title_text {
    font-size: 42px;
    margin-bottom: 0px;
    margin-left: 3%;
  }
}

@media (min-width: 1440px) {
  .ourServices__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    min-height: 320px;
    margin-top: -50px;
    margin-left: -50px;
  }
}

.ourServices__item {
  border-radius: 35px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 1440px) {
  .ourServices__item {
    margin-top: 50px;
    margin-left: 50px;
    -ms-flex-preferred-size: calc((100% - 2 * 50px) / 2);
        flex-basis: calc((100% - 2 * 50px) / 2);
  }
}

@media (max-width: 1439px) {
  .ourServices__item {
    margin-bottom: 30px;
  }
}

.ourServices__item_container {
  position: relative;
  padding: 30px 60px;
  min-height: 320px;
  border: 2px solid #bf986c;
  border-radius: 35px;
}

@media (max-width: 996px) {
  .ourServices__item_container {
    padding: 15px 30px;
  }
}

.ourServices__item:hover .item__title_text {
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.ourServices__item:hover .item__title_id {
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.ourServices__item:hover .item__title_link a {
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.ourServices__item:hover .item__title_arrow {
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.ourServices__item:hover .item__description {
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.ourServices__item:hover .item__link a {
  color: #fff;
  border: 2px solid #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.item__title_text {
  font-size: 38px;
  line-height: 120%;
  color: #fff;
  margin-bottom: 20px;
}

@media (max-width: 1280px) {
  .item__title_text {
    font-size: 34px;
  }
}

@media (max-width: 1024px) {
  .item__title_text {
    font-size: 32px;
  }
}

@media (max-width: 700px) {
  .item__title_text {
    font-size: 24px;
  }
}

@media (max-width: 650px) {
  .item__title_text {
    font-size: 26px;
  }
}

.item__title_link a {
  font-size: 18px;
  line-height: 130%;
  color: #fff;
}

@media (max-width: 700px) {
  .item__title_link a {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .item__title_link a {
    font-size: 18px;
  }
}

.item__description {
  text-align: left;
  font-family: "Arial";
  font-size: 20px;
  line-height: 130%;
  color: #fff;
  text-align: justify;
}

@media (max-width: 650px) {
  .item__description {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .item__description {
    font-size: 14px;
  }
}

.item__link {
  position: absolute;
  bottom: 40px;
  right: 60px;
}

@media (max-width: 996px) {
  .item__link {
    bottom: 25px;
    right: 30px;
  }
}

.item__link a {
  font-family: "Arial";
  font-size: 18px;
  line-height: 130%;
  color: #fff;
  padding: 10px 10px;
  border: 2px solid #bf986c;
  border-radius: 8px;
}

@media (max-width: 839px) {
  .item__link a {
    padding: 7px 5px;
  }
}

@media (max-width: 768px) {
  .item__link a {
    padding: 7px 5px;
  }
}

@media (max-width: 700px) {
  .item__link a {
    padding: 6px 2px;
  }
}

@media (max-width: 650px) {
  .item__link a {
    font-size: 16px;
    padding: 7px 7px;
  }
}

@media (max-width: 480px) {
  .item__link a {
    font-size: 14px;
  }
}

.criminal_law {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.criminal_law:hover {
  background-image: url(../../public/img/criminal_law_bg.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.civil_law {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.civil_law:hover {
  background-image: url(../../public/img/civil_law_bg.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.real_estate {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.real_estate:hover {
  background-image: url(../../public/img/real_estate_bg.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.commercial {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.commercial:hover {
  background-image: url(../../public/img/commercial_law_bg.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.insurance {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.insurance:hover {
  background-image: url(../../public/img/insurance_law.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.family_law {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.family_law:hover {
  background-image: url(../../public/img/family_law_bg.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.maritime {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.maritime:hover {
  background-image: url(../../public/img/maritime_law_bg.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.labour {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.labour:hover {
  background-image: url(../../public/img/labour_law_bg.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.banking {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.banking:hover {
  background-image: url(../../public/img/banking_law_bg.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.investment {
  background: url(../../public/img/opacity.png);
  -webkit-transition: background 1s ease;
  transition: background 1s ease;
}

.investment:hover {
  background-image: url(../../public/img/investment_law.webp);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
