.header__container {
  margin: 0 auto;
  padding: 23px 15px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 9999 !important;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.9)), color-stop(22%, rgba(0, 0, 0, 0.8)), color-stop(33%, rgba(0, 0, 0, 0.7)), color-stop(44%, rgba(0, 0, 0, 0.6)), color-stop(55%, rgba(0, 0, 0, 0.5)), color-stop(66%, rgba(0, 0, 0, 0.4)), color-stop(77%, rgba(0, 0, 0, 0.25)), color-stop(88%, rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.02)));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 22%, rgba(0, 0, 0, 0.7) 33%, rgba(0, 0, 0, 0.6) 44%, rgba(0, 0, 0, 0.5) 55%, rgba(0, 0, 0, 0.4) 66%, rgba(0, 0, 0, 0.25) 77%, rgba(0, 0, 0, 0.1) 88%, rgba(0, 0, 0, 0.02) 100%);
  -webkit-box-shadow: 0px 27px 24px 8px rgba(0, 0, 0, 0.01);
          box-shadow: 0px 27px 24px 8px rgba(0, 0, 0, 0.01);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 996px) {
  .header__container {
    display: none;
  }
}

.header__containerService {
  margin: 0 auto;
  padding: 10px 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999 !important;
  width: 100%;
  background-color: #fff;
  min-width: 100%;
  border-bottom: 2px solid #bf986c;
}

@media (max-width: 1600px) {
  .header__containerService {
    max-width: 1500px;
  }
}

@media (max-width: 1440px) {
  .header__containerService {
    max-width: 1340px;
  }
}

@media (max-width: 1280px) {
  .header__containerService {
    max-width: 1180px;
  }
}

@media (max-width: 1024px) {
  .header__containerService {
    max-width: 924px;
    padding: 15px 15px;
  }
}

@media (max-width: 996px) {
  .header__containerService {
    display: none;
  }
}

.header__navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

@media (max-width: 996px) {
  .header__navbar {
    display: none;
  }
}

.header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__logo_img img {
  max-width: 48px;
  margin-right: 30px;
}

@media (max-width: 1024px) {
  .header__logo_img img {
    max-width: 36px;
    margin-right: 15px;
  }
}

.header__logo_img-sticky img {
  max-width: 36px;
  margin-right: 30px;
}

@media (max-width: 1024px) {
  .header__logo_img-sticky img {
    max-width: 36px;
    margin-right: 15px;
  }
}

.header__logo_text {
  vertical-align: super;
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 100%;
  color: #bf986c;
}

@media (max-width: 1150px) {
  .header__logo_text {
    font-size: 44px;
  }
}

@media (max-width: 1024px) {
  .header__logo_text {
    font-size: 36px;
  }
}

.header__logo_text-sticky {
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 100%;
  color: #bf986c;
}

@media (max-width: 1150px) {
  .header__logo_text-sticky {
    font-size: 38px;
  }
}

@media (max-width: 1024px) {
  .header__logo_text-sticky {
    font-size: 36px;
  }
}

.header__logo_textService {
  vertical-align: super;
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 100%;
  color: #bf986c;
}

@media (max-width: 1150px) {
  .header__logo_textService {
    font-size: 44px;
  }
}

@media (max-width: 1024px) {
  .header__logo_textService {
    font-size: 36px;
  }
}

.header__logo_textService-sticky {
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 100%;
  color: #bf986c;
}

@media (max-width: 1150px) {
  .header__logo_textService-sticky {
    font-size: 38px;
  }
}

@media (max-width: 1024px) {
  .header__logo_textService-sticky {
    font-size: 36px;
  }
}

.header__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__menu_item {
  width: 40px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #ffffff;
  margin-left: 10px;
  padding: 4px 12px;
  border: 2px solid #bf986c;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 1280px) {
  .header__menu_item {
    font-size: 18px;
  }
}

@media (max-width: 1130px) {
  .header__menu_item {
    font-size: 16px;
  }
}

.header__menu_item:hover {
  border: 2px solid #bf986c;
  border-radius: 8px;
  background-color: #bf986cac;
}

.header__menu_itemService {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #000;
  margin-left: 15px;
  padding: 4px 12px;
  border: 2px solid #bf986c;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 1280px) {
  .header__menu_itemService {
    font-size: 18px;
  }
}

@media (max-width: 1130px) {
  .header__menu_itemService {
    font-size: 14px;
  }
}

.header__menu_itemService:hover {
  border: 2px solid #bf986c;
  background-color: #bf986cac;
  color: #fff;
  border-radius: 8px;
}

.header__menu_itemError {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #fff;
  margin-left: 15px;
  padding: 4px 12px;
  border: 2px solid transparent;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 1280px) {
  .header__menu_itemError {
    font-size: 18px;
  }
}

@media (max-width: 1130px) {
  .header__menu_itemError {
    font-size: 16px;
  }
}

.header__menu_itemError:hover {
  border: 2px solid #fff;
  border-radius: 8px;
}

.header__item_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6px 10px;
  margin-left: 10px;
  border: 2px solid #bf986c;
  border-radius: 8px;
  background-color: #bf986c;
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #000;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  width: 120px;
}

@media (max-width: 1280px) {
  .header__item_link {
    font-size: 18px;
  }
}

@media (max-width: 1130px) {
  .header__item_link {
    font-size: 16px;
  }
}

.header__item_link:hover {
  color: #fff;
  -webkit-box-shadow: 0px 4px 144px rgba(255, 237, 78, 0.85);
          box-shadow: 0px 4px 144px rgba(255, 237, 78, 0.85);
}

.header__item_linkService {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6px 10px;
  margin-left: 10px;
  border: 2px solid #bf986c;
  border-radius: 8px;
  background-color: #bf986c;
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #000;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 120px;
}

@media (max-width: 1280px) {
  .header__item_linkService {
    font-size: 18px;
  }
}

@media (max-width: 1130px) {
  .header__item_linkService {
    font-size: 16px;
  }
}

.header__item_linkService:hover {
  color: #fff;
  -webkit-box-shadow: 0px 4px 44px #bf986c;
          box-shadow: 0px 4px 44px #bf986c;
}
