.swiper-slide img {
  -webkit-filter: brightness(0.7);
          filter: brightness(0.7);
  --ratio: 1584 / 3288;
  display: block;
  width: 100%;
  min-height: calc(117vw * var(--ratio));
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: 2s;
  transition: 2s;
  position: relative;
}

.swiper-slide img:hover {
  -webkit-filter: none;
          filter: none;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform-origin: center;
          transform-origin: center;
}

.swiper-slide {
  overflow-x: hidden;
  overflow-y: hidden;
}
