.navigationRigth__up {
  text-align: end;
  width: 1%;
  height: 1%;
  border-radius: 50%;
  position: -webkit-sticky;
  position: sticky;
  margin-top: -70px;
  bottom: 50px;
  left: 94%;
  z-index: 100;
}

@media (max-width: 768px) {
  .navigationRigth__up {
    display: none;
  }
}

.navigationRigth__link {
  color: #bf986c;
}

.navigationRigth__link:hover {
  cursor: pointer;
  color: #d5c31e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.navigationRigth__link:active {
  color: #bf986c;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}
