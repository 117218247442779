.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-animation-name: appear;
          animation-name: appear;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
}

.modal__dialog {
  width: 100%;
  max-width: 550px;
  background: white;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-name: slide-in;
          animation-name: slide-in;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.modal__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem;
  border-bottom: 2px solid #bf986c;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.modal__title {
  width: 100%;
  text-align: left;
  font-family: "Arial";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #1a1919;
}

.modal__close {
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  color: #1a1919;
  margin-top: -10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.modal__close:hover {
  color: #bf986c;
}

.modal__body {
  overflow-y: hidden;
}

.modal__content {
  padding: 1rem;
}

.modal__content_title {
  font-size: 18px;
  font-weight: 600;
  color: #1a1919;
  margin-bottom: 25px;
}

.modal__content_name input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__content_name-error input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ec1b24;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__content_email input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__content_email-error input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ec1b24;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__content_tel input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__content_tel-error input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ec1b24;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__content_captcha {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem;
}

.modal__content_captcha-img {
  max-width: 30%;
}

.modal__content_captcha-button {
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #1a1919;
  font-size: 24px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.modal__content_captcha-button:hover {
  color: #bf986c;
}

.modal__content_captcha-input {
  width: 30%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__content_captcha-input-error {
  width: 30%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ec1b24;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__content_message-textarea {
  width: 100%;
  height: 75px;
  resize: none;
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__content_message-textarea-error {
  width: 100%;
  height: 75px;
  resize: none;
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ec1b24;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  border-radius: 0.25rem;
  outline: none;
}

.modal__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem;
  border-top: 2px solid #bf986c;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.modal__footer button {
  border: 2px solid #bf986c;
  padding: 5px 20px;
  border-radius: 5px;
  color: #1a1919;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.modal__footer button:hover {
  color: #fff;
  background-color: #bf986cac;
}

.modal__success {
  padding: 224px 0px;
  text-align: center;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #1a1919;
}

@-webkit-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slide-in {
  from {
    -webkit-transform: translateY(-150px);
            transform: translateY(-150px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-in {
  from {
    -webkit-transform: translateY(-150px);
            transform: translateY(-150px);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
