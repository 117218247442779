@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
html {
  height: 100%;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

body {
  max-width: 1920px;
  min-height: 100%;
  margin: 0 auto;
  background-color: #161616;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

@media (max-width: 1600px) {
  body {
    max-width: 1600px;
  }
}

@media (max-width: 1440px) {
  body {
    max-width: 1440px;
  }
}

@media (max-width: 1280px) {
  body {
    max-width: 1280px;
  }
}

@media (max-width: 1024px) {
  body {
    max-width: 1024px;
  }
}

.preloader_box {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.preloader_text {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 59.1437px;
  line-height: 100%;
  color: #bf986c;
}

@media (max-width: 1601px) {
  .preloader_box {
    width: 300px;
    height: 300px;
  }
  .preloader_text {
    font-size: 48px;
  }
}

@media (max-width: 1441px) {
  .preloader_box {
    width: 300px;
    height: 300px;
  }
  .preloader_text {
    font-size: 48px;
  }
}

@media (max-width: 1281px) {
  .preloader_box {
    width: 250px;
    height: 250px;
  }
  .preloader_text {
    font-size: 44px;
  }
}

@media (max-width: 931px) {
  .preloader_box {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 768px) {
  .preloader_box {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 700px) {
  .preloader_box {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 650px) {
  .preloader_box {
    width: 200px;
    height: 200px;
  }
  .preloader_text {
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .preloader_box {
    width: 200px;
    height: 200px;
  }
  .preloader_text {
    font-size: 36px;
  }
}

@media (max-width: 500px) {
  .preloader_box {
    width: 200px;
    height: 200px;
  }
  .preloader_text {
    font-size: 36px;
  }
}

@media (max-width: 480px) {
  .preloader_box {
    width: 175px;
    height: 175px;
  }
  .preloader_text {
    font-size: 32px;
  }
}

@media (max-width: 431px) {
  .preloader_box {
    width: 175px;
    height: 175px;
  }
  .preloader_text {
    font-size: 32px;
  }
}

@media (max-width: 400px) {
  .preloader_box {
    width: 175px;
    height: 175px;
  }
  .preloader_text {
    font-size: 32px;
  }
}

@media (max-width: 376px) {
  .preloader_box {
    width: 150px;
    height: 150px;
  }
  .preloader_text {
    font-size: 28px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}