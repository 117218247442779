.footer {
  border-top: 2px solid #bf986c;
  background-color: #ffffff;
}

.footer__container {
  max-width: 1700px;
  margin: 0 auto;
  padding-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1600px) {
  .footer__container {
    max-width: 1400px;
  }
}

@media (max-width: 1440px) {
  .footer__container {
    max-width: 1240px;
  }
}

@media (max-width: 1280px) {
  .footer__container {
    max-width: 1080px;
  }
}

@media (max-width: 1100px) {
  .footer__container {
    max-width: 950px;
  }
}

@media (max-width: 1024px) {
  .footer__container {
    max-width: 924px;
  }
}

@media (max-width: 931px) {
  .footer__container {
    max-width: 831px;
  }
}

@media (max-width: 839px) {
  .footer__container {
    max-width: 739px;
  }
}

@media (max-width: 768px) {
  .footer__container {
    max-width: 668px;
  }
}

@media (max-width: 700px) {
  .footer__container {
    max-width: 600px;
  }
}

@media (max-width: 650px) {
  .footer__container {
    max-width: 450px;
  }
}

@media (max-width: 480px) {
  .footer__container {
    max-width: 380px;
  }
}

@media (max-width: 414px) {
  .footer__container {
    max-width: 314px;
  }
}

.footer__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

@media (max-width: 650px) {
  .footer__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.footer__logo {
  margin-right: 70px;
}

@media (max-width: 1100px) {
  .footer__logo {
    margin-right: 20px;
  }
}

@media (max-width: 839px) {
  .footer__logo {
    margin-right: 15px;
  }
}

@media (max-width: 650px) {
  .footer__logo {
    margin-right: 0px;
  }
}

.footer__logo_top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer__logo_img {
  max-width: 54px;
  margin-right: 30px;
}

@media (max-width: 1024px) {
  .footer__logo_img {
    max-width: 27px;
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .footer__logo_img {
    max-width: 20px;
  }
}

@media (max-width: 650px) {
  .footer__logo_img {
    max-width: 32px;
  }
}

.footer__logo_text {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 59.1437px;
  line-height: 100%;
  color: #bf986c;
}

@media (max-width: 1100px) {
  .footer__logo_text {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .footer__logo_text {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .footer__logo_text {
    font-size: 26px;
  }
}

@media (max-width: 650px) {
  .footer__logo_text {
    font-size: 36px;
  }
}

.footer__kinds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer__kinds_item {
  margin-bottom: 17px;
}

.footer__kinds_item a {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  color: #000000;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .footer__kinds_item a {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .footer__kinds_item a {
    font-size: 17px;
  }
}

.footer__kinds_item a:hover {
  color: #bf986c;
  text-decoration: underline;
}

.footer__kinds_left {
  margin-top: 66px;
  margin-right: 56px;
}

@media (max-width: 1024px) {
  .footer__kinds_left {
    margin-right: 30px;
  }
}

@media (max-width: 650px) {
  .footer__kinds_left {
    margin-top: 35px;
  }
}

.footer__kinds_right {
  margin-top: 66px;
  margin-right: 68px;
}

@media (max-width: 1024px) {
  .footer__kinds_right {
    margin-right: 30px;
  }
}

@media (max-width: 650px) {
  .footer__kinds_right {
    margin-top: 35px;
  }
}

.footer__contacts {
  margin-top: 66px;
}

@media (max-width: 650px) {
  .footer__contacts {
    margin-top: 41px;
  }
}

.footer__contacts_tel {
  margin-bottom: 6px;
}

.footer__contacts_tel a {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  color: #000000;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 931px) {
  .footer__contacts_tel a {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .footer__contacts_tel a {
    font-size: 17px;
  }
}

@media (max-width: 650px) {
  .footer__contacts_tel a {
    font-size: 17px;
  }
}

.footer__contacts_tel a:hover {
  color: #bf986c;
  text-decoration: underline;
}

.footer__contacts_email {
  margin-bottom: 6px;
}

.footer__contacts_email a {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  color: #000000;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 931px) {
  .footer__contacts_email a {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .footer__contacts_email a {
    font-size: 17px;
  }
}

@media (max-width: 650px) {
  .footer__contacts_email a {
    font-size: 17px;
  }
}

.footer__contacts_email a:hover {
  color: #bf986c;
  text-decoration: underline;
}

.footer__contacts_address {
  max-width: 292px;
}

.footer__contacts_address a {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  color: #000000;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .footer__contacts_address a {
    font-size: 17px;
  }
}

.footer__contacts_address a:hover {
  color: #bf986c;
  text-decoration: underline;
}

.footer__contacts_title {
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 3px;
}

.footer__contacts_social-networks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 35px;
}

.footer__contacts_social-networks a {
  margin-right: 45px;
  font-size: 32px;
  color: #000000;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 839px) {
  .footer__contacts_social-networks a {
    margin-right: 20px;
  }
}

@media (max-width: 700px) {
  .footer__contacts_social-networks a {
    margin-right: 15px;
  }
}

.footer__contacts_social-networks a:hover {
  color: #bf986c;
}

.footer__middle {
  margin-top: 68px;
  margin-bottom: 45px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.4);
  text-align: justify;
}

@media (max-width: 768px) {
  .footer__middle {
    font-size: 12px;
  }
}

.footer__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 75px;
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  .footer__bottom {
    font-size: 12px;
  }
}

@media (max-width: 650px) {
  .footer__bottom {
    font-size: 16px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}

@media (max-width: 480px) {
  .footer__bottom {
    font-size: 14px;
  }
}

@media (max-width: 414px) {
  .footer__bottom {
    font-size: 11px;
  }
}

.footer__bottom a {
  color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer__bottom a:hover {
  color: #bf986c;
  text-decoration: underline;
}

@media (max-width: 650px) {
  .footer__resived {
    margin-right: 39%;
  }
}

.footer__policy {
  margin-left: -50%;
}

@media (max-width: 1280px) {
  .footer__policy {
    margin-left: -30%;
  }
}

@media (max-width: 1100px) {
  .footer__policy {
    margin-left: -35%;
  }
}

@media (max-width: 1024px) {
  .footer__policy {
    margin-left: -30%;
  }
}

@media (max-width: 931px) {
  .footer__policy {
    margin-left: -25%;
  }
}

@media (max-width: 839px) {
  .footer__policy {
    margin-left: -15%;
  }
}

@media (max-width: 650px) {
  .footer__policy {
    margin-left: 0%;
    margin-right: 4%;
  }
}

@media (max-width: 480px) {
  .footer__policy {
    margin-right: 2%;
  }
}

@media (max-width: 414px) {
  .footer__policy {
    margin-right: 1%;
  }
}

@media (max-width: 650px) {
  .footer__dib {
    margin-left: 0%;
  }
}

@media (max-width: 480px) {
  .footer__dib {
    margin-left: 0%;
  }
}

@media (max-width: 414px) {
  .footer__dib {
    margin-left: 0%;
  }
}

.licensed__title {
  font-family: "Playfair Display";
  text-align: center;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #000;
}

@media (max-width: 1020px) {
  .licensed__title {
    margin-bottom: 20px;
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .licensed__title {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .licensed__title {
    font-size: 17px;
  }
}

.licensed__image-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1020px) {
  .licensed__image-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (max-width: 650px) {
  .licensed__image-box {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

@media (max-width: 650px) {
  .licensed__image-box_item:first-child {
    margin-right: 15px;
  }
}

.licensed__image-box_item img {
  max-width: 200px;
}

@media (max-width: 768px) {
  .licensed__image-box_item img {
    max-width: 150px;
  }
}
